body {
  margin: 0;
  padding: 0;
  background-color: white !important;
  background-size: cover;
  background-repeat: no-repeat;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.load-from-below {
  animation: 1s ease-out 0s 1 slideInFromBottom;
}

.upper-shadow {
  box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.2) !important;
}

.offer_button:hover {
  box-shadow: 2px 2px 4px blue !important;
  -webkit-box-shadow: 0px 0px 4px blue !important;
  -moz-box-shadow: 2px 2px 4px blue !important;
  -o-box-shadow: 2px 2px 4px blue !important;
  color: black !important;
  background-color: white !important;
}

.lp-background {
  background: url("https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Flp_banner_new.jpg?alt=media&token=e8c4144c-0280-4c85-95b2-450198817c75");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.contact-background {
  background: url("https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_contact.jpg?alt=media&token=3aa83665-ef31-4d6c-a052-22a0e9a6d980&_gl=1*1deobns*_ga*MTMyMDQ1OTE2LjE2NDQzMTI5Mjg.*_ga_CW55HF8NVT*MTY5NTk4MTM3Ny44MC4xLjE2OTU5ODMxNDAuNjAuMC4w");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.backscroll-image {
  position: fixed;
  z-index: -5;
  object-fit: cover;
}

.dark-tint {
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  min-width: 100%;
  min-height: 100%;
  background-color: black;
  opacity: 0.5;
  filter: alpha(opacity=70);
  -webkit-filter: alpha(opacity=70);
  text-align: center;
}
.orange-tint {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: scale(0, 0);
  transition: transform 0.2s;
  -moz-transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
  background-color: #d40402;
  opacity: 0.7;
  filter: alpha(opacity=70);
  -webkit-filter: alpha(opacity=70);
  text-align: center;
}

.car-type-car-names {
  display: block;
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  transform: scaleY(0) translateY(-100%);
  -moz-transform: scaleY(0) translateY(-100%);
  -webkit-transform: scaleY(0) translateY(-100%);
  transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  background-color: black;
  opacity: 0.7;
  filter: alpha(opacity=70);
  -webkit-filter: alpha(opacity=70);
  text-align: center;
}

.car-type-image-container {
  position: relative;
}

.car-type-image-container:hover > .orange-tint,
.car-type-image-container:hover > .car-type-car-names {
  transform: scaleY(1) translateY(0);
}

.dark-tint-text {
  color: white;
  display: "table-cell";
  vertical-align: middle;
}

.hover-image {
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  min-width: 100%;
  min-height: 100%;
  background-color: #d35f29;
  opacity: 0.9;
  filter: alpha(opacity=90);
  -webkit-filter: alpha(opacity=90);
  text-align: center;
}

.hover-image:hover {
  width: 0%;
  height: 0%;

  background-color: #f5bd20;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-filter: alpha(opacity=100);

  transform: scale(0.7, 0.7);
  -webkit-transform: scale(0.7, 0.7);
  -moz-transform: scale(0.7, 0.7);
  -o-transform: scale(0.7, 0.7);

  transition: all 750ms;
  -o-transition: all 750ms;
  -moz-transition: all 750ms;
  -webkit-transition: all 750ms;
}

.no-shadow-border {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: none !important;
  border-radius: 0px !important;
}

.rotate_on_hover:hover {
  transition: all 200ms;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  -ms-transform: rotate(-40deg); /* IE 9 */
  -webkit-transform: rotate(-40deg); /* Safari */
  transform: rotate(-40deg);
}

.shrink_on_hover:hover {
  transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -ms-transform: scale(0.8, 0.8); /* IE 9 */
  -webkit-transform: scale(0.8, 0.8); /* Safari */
  transform: scale(0.8, 0.8);
}

.rise_on_hover:hover {
  cursor: pointer;
  transition: all 20ms ease-in-out;
  -webkit-transition: all 20ms ease-in-out;
  -moz-transition: all 20ms ease-in-out;
  -o-transition: all 20ms ease-in-out;
  -ms-transform: translate(0px, -4px) scale(1.1, 1.1); /* IE 9 */
  -webkit-transform: translate(0px -4px) scale(1.1, 1.1); /* Safari */
  transform: translate(0px, -4px) scale(1.1, 1.1);
}

#u_0_1 {
  display: none;
}

.opposite-curve {
  border-radius: 0px 40px 0px 40px !important;
  border-bottom: 2px solid red !important;
  border-top: 2px solid red !important;
}

.shadow-one {
  -moz-box-shadow: 2px 2px 5px 1px rgba(68, 68, 68, 0.6) !important;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(68, 68, 68, 0.6) !important;
  box-shadow: 2px 2px 5px 1px rgba(68, 68, 68, 0.6) !important;
}

#individual_services {
  transition: all 0.2s;
}

.orange-gradient {
  background: #f7971e; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffd200,
    #f7971e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffd200,
    #f7971e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.light-blue-gradient {
  background: #ed213a !important; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ed213a,
    #93291e
  ) !important; /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ed213a,
    #93291e
  ) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.black-color {
  background-color: #000000;
}

.news-img {
  margin: "5% 5%";
}
.img-responsive {
  width: 50%;
  float: left;
  height: 50%;
}

@keyframes slideInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(+50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* @keyframes changeColorAnimation {
  from {
    background-color: red !important;
  }
  to {
    background-color: green !important;
  }
}
.animate-colors {
  animation-name: changeColorAnimation !important;
  animation-duration: 0.2s !important;
  animation-iteration-count: infinite !important;
} */

.ui.popup {
  will-change: auto;
  z-index: 999 !important;
}

.ui.modal {
  will-change: auto;
  z-index: 2000 !important;
}

p,
div {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.spaced-list .item {
  padding: 2% 0% !important;
}

.spaced-list .item:hover {
  color: black !important;
}

/* Shadow for Segments */
.breathing-shadow {
  border: 1px solid #ebe9e9 !important;
  border-radius: "20px" !important;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05),
    0px 10px 30px rgba(0, 0, 0, 0.05) !important;
}

.segment-dark-one {
  background-color: "#F8F6F6" !important;
}

.segment-dark-two {
  background-color: "#F1F1F1" !important;
}

/* Hiding Scrollbar for Scrollers */
.hide-native-scrollbar {
  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* Internet Explorer 11 */
}
.hide-native-scrollbar::-webkit-scrollbar {
  /** WebKit */
  display: none;
}

.hover-highlight:hover {
  transition: all 200ms !important;
  color: teal !important;
  font-size: 1.05em !important;
}

.booking-form {
  margin: 1rem 0px !important;
}

.booking-form input {
  padding: 1rem 0px 0px 0px!important;
  border: none !important;
}

.location-search input {
  padding: 0px !important;
  border: none !important;
}

.autocomplete-dropdown-container {
  border:none !important
}

.dark-dropdown {
  background: transparent !important;
  color: #FFFFFF !important; 
  border: none !important;
  padding-left: 0px !important;
}

.dark-dropdown input {
  background: transparent !important;
  color: #FFFFFF !important; 
  border-color: white !important;
  
}

.ui.selection.visible.dropdown>.text:not(.default) {
  color: #FFFFFF !important; 
}

.dark-form input {
  background: transparent !important;
  padding-left: 0px !important;
  color:white !important;
  border: none !important;
}
.passenger-form input {
  padding: 1rem !important;
}

.stop-scroll {
  overflow-y: hidden !important;
}

.ui.secondary.segment {
  background-color: #F8F6F6 !important;
  color: rgba(0,0,0,.6) !important;
}


