h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea {
  font-family: "Poppins", sans-serif !important;
}

.content-font {
  font-family: "Poppins", sans-serif !important;
}

.title-font {
  font-family: "Poppins", sans-serif !important;
}

.ui {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
}

@media only screen and (max-width: 767px) {
  .small-text {
    font-weight: 400;
    font-size: 0.5em;
    line-height: 1.2rem !important;
  }
  .medium-text {
    font-weight: 400;
    font-size: 0.7em;
    line-height: 1.4rem !important;
  }
  .large-text {
    font-weight: 400;
    font-size: 1em;
    line-height: 1.4rem !important;
  }
  .title-small {
    font-size: 0.7em;
    font-weight: 700 !important;
    
    line-height: 1.4rem !important;
  }
  .title-medium {
    font-size: 1em;
    font-weight: 700 !important;
    
    line-height: 1.8rem !important;
  }
  .title-large {
    font-size: 1.2em;
    font-weight: 700 !important;
    line-height: 2rem !important;
    
  }
  .heading-text {
    font-size: 1.5em;
    font-weight: 700 !important;
  }
}

/* Tablet */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .small-text {
    font-weight: 400;
    font-size: 0.5em;
    line-height: 1.2rem !important;
  }

  .medium-text {
    font-size: 0.7em;
    font-weight: 400;
    line-height: 1.4rem !important;
  }
  .large-text {
    font-weight: 400;
    font-size: 1em;
    line-height: 1.8rem !important;
  }
  .title-small {
    font-size: 0.7em;
    font-weight: 700 !important;

    line-height: 1.4rem !important;
  }
  .title-medium {
    font-size: 1em;
    font-weight: 700 !important;

    line-height: 1.8rem !important;
  }
  .title-large {
    font-size: 1.3em;
    font-weight: 700 !important;
    line-height: 2.1rem !important;
  }
  .heading-text {
    font-size: 1.7em;
    font-weight: 700 !important;
  }
}

/* Computer/Desktop Monitor */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .small-text {
    font-size: 0.7em;
    font-weight: 400;
    line-height: 1.5rem !important;
  }
  .medium-text {
    font-size: 0.9em;
    font-weight: 400;
    line-height: 1.7rem !important;
  }
  .large-text {
    font-weight: 400;
    font-size: 1.2em;
    line-height: 2rem !important;
  }
  .title-small {
    font-size: 0.9em;
    font-weight: 700 !important;

    line-height: 1.7rem !important;
  }
  .title-medium {
    font-size: 1.2em;
    font-weight: 700 !important;

    line-height: 2rem !important;
  }
  .title-large {
    font-size: 1.5em;
    font-weight: 700 !important;
    line-height: 2.3rem !important;
  }
  .heading-text {
    font-size: 2em;
    font-weight: 700 !important;
  }
}

/* Large Monitor */

@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  .small-text {
    font-weight: 400;
    font-size: 0.8em;
    line-height: 1.6rem !important;
  }
  .medium-text {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.8rem !important;
  }
  .large-text {
    font-weight: 400;
    font-size: 1.2em;
    line-height: 2rem !important;
  }
  .title-small {
    font-size: 1em;
    font-weight: 700 !important;

    line-height: 1.8rem !important;
  }
  .title-medium {
    font-size: 1.3em;
    font-weight: 700 !important;

    line-height: 2.1rem !important;
  }
  .title-large {
    font-size: 1.9em;
    font-weight: 700 !important;
    line-height: 2.7rem !important;
  }
  .heading-text {
    font-size: 2.5em;
    font-weight: 700 !important;
  }
}

/* WideScreen Monitor */
@media only screen and (min-width: 1920px) {
  .small-text {
    font-weight: 400;
    font-size: 1em;
    line-height: 1.8rem !important;
  }
  .medium-text {
    font-weight: 400;
    font-size: 1.2em;
    line-height: 2rem !important;
  }
  .large-text {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 2.2rem !important;
  }
  .title-small {
    font-size: 1.1em;
    font-weight: 700 !important;
    line-height: 1.9rem !important;
  }
  .title-medium {
    font-size: 1.4em;
    font-weight: 700 !important;
    line-height: 2.2rem !important;
  }
  .title-large {
    font-size: 1.9em;
    font-weight: 700 !important;
    line-height: 2.7rem !important;
  }
  .heading-text {
    font-size: 2.5em;
    font-weight: 700 !important;
  }
}
