.airport-transfers {
    padding: 20px;
  }
  
  .suburbs-list {
    list-style-type: none;
    padding: 0;
  }
  
  .suburbs-list li {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 5px;
    transition: background-color 0.2s;
  }
  
  .suburbs-list li:hover {
    background-color: #f0f0f0;
  }
  
  .suburbs-list li h3 {
    margin: 0;
  }
  
  .suburbs-list li p {
    margin: 10px 0 0 0;
  }
  