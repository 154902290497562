.faqSection {
    background-color: #f9f9f9;
    padding: 50px 20px;
    text-align: center;
  }
  
  .faqHeader {
    margin-bottom: 40px;
  }
  
  .faqTitle {
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .faqDescription {
    color: #666;
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .faqList {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .faqItem {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .faqItem:hover {
    background-color: #f5f5f5;
  }
  
  .faqQuestion {
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .faqAnswer {
    color: #666;
    display: none;
  }
  
  .faqItem.active .faqAnswer {
    display: block;
  }
  
  .faqImageContainer {
    margin-top: 50px;
  }
  
  .faqImage {
    max-width: 100%;
    height: auto;
  }

  .faqItem {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform transition */
  }
  
  .faqItem:hover {
    background-color: #f5f5f5;
    transform: translateY(-3px); /* Lifts the tile slightly on hover */
  }
  
  .faqAnswer {
    color: #666;
    display: none;
    transition: max-height 0.3s ease, opacity 0.3s ease; /* Added transition for max-height and opacity */
    overflow: hidden; /* Hides overflow content */
    max-height: 0; /* Initial max-height is 0 */
    opacity: 0; /* Initial opacity is 0 */
  }
  
  .faqItem.active .faqAnswer {
    display: block;
    max-height: 500px; /* Adjust the max-height to accommodate the answer content */
    opacity: 1; /* Fades in the answer content */
  }
  
  