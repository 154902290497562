.tac-approved-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  
  .tac-approved-badge {
    display: inline-flex;
    align-items: center;
    background-color: #e0f7fa;
    color: #00796b;
    padding: 8px 16px;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    font-weight: 500;
    font-size: 14px;
  }
  
  .tac-approved-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: #00796b;
  }
  